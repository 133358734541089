import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Paragraph } from '@hl-portals/ui';

import { useModal } from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';
import { ReviewEligibilityStatusModal } from '../../review-eligibility-status-modal';

export const CreditScore = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();

  const { watch, setValue } = useFormContext();
  const { openModal, closeModal, isModalOpened } = useModal();
  const { credit_score, has_accepted_credit_score_modal } = watch();

  const onSelect = (selection: boolean) => {
    // if true, credit score is above 620.
    // if false, credit score is below 620
    setValue('credit_score', selection);
    if (selection) {
      onUpdateSnapshot({ credit_score: selection });
      return;
    }

    if (has_accepted_credit_score_modal && !selection) {
      onUpdateSnapshot({ credit_score: selection });
      return;
    }
  };

  useEffect(() => {
    if (has_accepted_credit_score_modal) {
      return;
    }

    if (credit_score === false && !isModalOpened) {
      openModal(
        <ReviewEligibilityStatusModal
          onConfirm={() => {
            setValue('has_accepted_credit_score_modal', true);
            onUpdateSnapshot({ credit_score });
            closeModal({ clearAll: true });
          }}
          title={
            <Paragraph fontWeight="600" pb="4px">
              Low credit score
            </Paragraph>
          }
          description={
            <Paragraph fontWeight="400">
              To approve the equity loan, at least 1 title owner must have a
              credit score of 620 or higher.
            </Paragraph>
          }
          renderFootnote
        />,
        { hideCloseIcon: true, enableClose: false }
      );
    }
  }, [credit_score, has_accepted_credit_score_modal]);

  return (
    <>
      <Content>
        <Container
          title="Is your client’s credit score above 620?"
          subtitle="Only one title holder of the departing residence needs to meet this requirement."
        >
          <Box flex="1" flexDirection="column">
            <Box mb="20px" flexDirection="column" gap="16px">
              <CardRadio
                title="Yes"
                onClick={() => onSelect(true)}
                isActive={credit_score === true}
                justifyContent="center"
              />
              <CardRadio
                title="No"
                isActive={credit_score === false}
                onClick={() => onSelect(false)}
                justifyContent="center"
              />
            </Box>
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            The Equity Unlock loan requires a minimum credit score. If not met,
            your client may still be eligible for other program benefits.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() => onUpdateSnapshot({ credit_score })}
          disabled={credit_score === undefined}
        />
      </Footer>
    </>
  );
};
