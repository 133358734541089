import { useSaveFlowRepInfo } from 'apps/equity-app/hooks/useSaveFlowRepInfo';

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Inputs, Paragraph } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

const LOCAL_STORAGE_SAVE_TXN_CO_INFO = 'lead-submission-save-txn-co-info';

export const AddTransactionCoordinatorInfo = () => {
  const { updateStoredValues, clearStoredValues, storedValues } =
    useSaveFlowRepInfo(LOCAL_STORAGE_SAVE_TXN_CO_INFO, {});
  const { onUpdateSnapshot } = useLeadSubmissionContext();
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const {
    transaction_coordinator_name,
    transaction_coordinator_email,
    transaction_coordinator_phone,
    save_transaction_coordinator_info,
  } = watch();

  const handleNext = () => {
    if (save_transaction_coordinator_info) {
      updateStoredValues({
        transaction_coordinator_name,
        transaction_coordinator_email,
        transaction_coordinator_phone,
        save_transaction_coordinator_info,
      });
    } else {
      clearStoredValues();
    }

    onUpdateSnapshot({
      transaction_coordinator_name: transaction_coordinator_name ?? '',
      transaction_coordinator_email: transaction_coordinator_email ?? '',
      transaction_coordinator_phone: transaction_coordinator_phone ?? '',
      save_transaction_coordinator_info:
        save_transaction_coordinator_info ?? false,
    });
  };

  const someFieldsHaveErrors: boolean = [
    errors?.transaction_coordinator_name?.message,
    errors?.transaction_coordinator_email?.message,
    errors?.transaction_coordinator_phone?.message,
  ].some(Boolean);

  const disable = someFieldsHaveErrors;

  useEffect(() => {
    if (storedValues?.save_transaction_coordinator_info) {
      setValue(
        'transaction_coordinator_name',
        storedValues?.transaction_coordinator_name
      );
      setValue(
        'transaction_coordinator_email',
        storedValues?.transaction_coordinator_email
      );
      setValue(
        'transaction_coordinator_phone',
        storedValues?.transaction_coordinator_phone
      );
      setValue(
        'save_transaction_coordinator_info',
        storedValues?.save_transaction_coordinator_info
      );
    }
  }, []);

  return (
    <>
      <Content>
        <Container
          title="Add a Transaction Coordinator"
          subtitle="(Optional) You can also add it after completing the client’s application."
        >
          <Box flex="1" flexDirection="column">
            <Box flexDirection="column" gap="32px">
              <Inputs.Text
                name="transaction_coordinator_name"
                label="Full name"
                placeholder="Enter coordinator’s full name"
                mode="onBlur"
              />
              <Inputs.Email
                name="transaction_coordinator_email"
                label="Email Address"
                placeholder="coordinator@example.com"
                mode="onBlur"
              />
              <Inputs.Phone
                name="transaction_coordinator_phone"
                label="Phone Number"
                placeholder="555-555-5555"
                mode="onBlur"
              />
              <Inputs.Checkbox name="save_transaction_coordinator_info">
                <Paragraph variant="text" textAlign="left">
                  Save team member for future transactions
                </Paragraph>
              </Inputs.Checkbox>
            </Box>
          </Box>
          <Alert
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
            mt={{ xs: '24px', md: '40px' }}
          >
            We will include the team member in email communications so they can
            assist with the transaction.
          </Alert>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={() => handleNext()} disabled={disable} />
      </Footer>
    </>
  );
};
