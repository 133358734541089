import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Paragraph } from '@hl-portals/ui';

import { useModal } from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';
import { ReviewEligibilityStatusModal } from '../../review-eligibility-status-modal';

export const PrimaryResidence = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();

  const { watch, setValue } = useFormContext();
  const { openModal, closeModal, isModalOpened } = useModal();
  const { primary_residence, has_accepted_primary_residence_modal } = watch();

  const onSelect = (selection: boolean) => {
    setValue('primary_residence', selection);

    if (selection) {
      onUpdateSnapshot({ primary_residence: selection });
      return;
    }

    if (has_accepted_primary_residence_modal && !selection) {
      onUpdateSnapshot({ primary_residence: selection });
      return;
    }
  };

  useEffect(() => {
    if (has_accepted_primary_residence_modal) {
      return;
    }

    if (primary_residence === false && !isModalOpened) {
      openModal(
        <ReviewEligibilityStatusModal
          onConfirm={() => {
            setValue('has_accepted_primary_residence_modal', true);
            onUpdateSnapshot({ primary_residence });
            closeModal({ clearAll: true });
          }}
          title={
            <Paragraph fontWeight="600" pb="4px">
              Primary residence
            </Paragraph>
          }
          description={
            <Paragraph fontWeight="400">
              The program is available for primary residences and cannot accept
              second homes or investment properties.
            </Paragraph>
          }
          renderFootnote
        />,
        { hideCloseIcon: true, enableClose: false }
      );
    }
  }, [primary_residence, has_accepted_primary_residence_modal]);

  return (
    <>
      <Content>
        <Container title="Is your client moving from one primary residence to another?">
          <Box flex="1" flexDirection="column">
            <Box mb="20px" flexDirection="column" gap="16px">
              <CardRadio
                title="Yes"
                onClick={() => onSelect(true)}
                isActive={primary_residence === true}
                justifyContent="center"
              />
              <CardRadio
                title="No"
                isActive={primary_residence === false}
                onClick={() => onSelect(false)}
                justifyContent="center"
              />
            </Box>
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            The program works best if the incoming & departing residences are
            primary homes.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() => onUpdateSnapshot({ primary_residence })}
          disabled={primary_residence === undefined}
        />
      </Footer>
    </>
  );
};
