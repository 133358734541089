import { STEPS, Step } from '../../metadata/constants';
import { FormData } from '../../metadata/forms';

export function getStepsToSkip(formData: FormData): Step[] {
  const { is_home_owned, current_flow, finance_type, trust, under_contract } =
    formData;
  const commonSteps = [];

  if (is_home_owned) {
    commonSteps.push(STEPS.LIENS_BALANCES);
  }

  if (trust?.dr_held_in_trust) {
    commonSteps.push(STEPS.TRUST);
  }

  if (under_contract === 'yes') {
    commonSteps.push(STEPS.NEW_HOME_PURCHASE_TIMELINE);
    commonSteps.push(STEPS.NEW_HOME_TARGET_EQUITY_AMT);
  }

  if (under_contract === 'no') {
    commonSteps.push(STEPS.NEW_HOME_CLOSING_DATE);
    commonSteps.push(STEPS.NEW_HOME_PURCHASE_FUNDS);
    commonSteps.push(STEPS.NEW_HOME_PURCHASE_CONTRACT);
  }

  if (current_flow === 'lender') {
    return [
      ...commonSteps,
      STEPS.LOAN_OFFICER_INFORMATION, // phase 1
      STEPS.FINANCIAL_TYPE, // phase 1
      STEPS.ADD_LOAN_OFFICER_INFO, // phase 2
      STEPS.ADD_TRANSACTION_COORDINATOR_INFO, // phase 2
    ];
  }

  if (current_flow === 'agent') {
    if (finance_type === 'cash') {
      return [
        ...commonSteps,
        STEPS.FINANCIAL_INFORMATION, // phase 1
        STEPS.AGENT_INFORMATION, // phase 1
        STEPS.ADD_AGENT_INFO, // phase 2
        STEPS.ADD_ASSISTANT_INFO, // phase 2
      ];
    }
    return [
      ...commonSteps,
      STEPS.AGENT_INFORMATION,
      STEPS.ADD_AGENT_INFO,
      STEPS.ADD_ASSISTANT_INFO,
    ];
  }

  return [];
}
