import { useSaveFlowRepInfo } from 'apps/equity-app/hooks/useSaveFlowRepInfo';

import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Inputs, Paragraph } from '@hl-portals/ui';

import { getFullAddress } from '@hl-portals/helpers';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';
import { LOCAL_STORAGE_ADDRESS_SEARCH_SETTINGS } from './constants';

// ====================================================================
// Manual Search

type ManualSearchProps = {
  setManual: () => void;
};

const ManualSearch = ({ setManual }: ManualSearchProps) => {
  const { watch, setValue } = useFormContext();
  const {
    property_address,
    property_city,
    property_state,
    property_postal_code,
  } = watch();

  useEffect(() => {
    if (
      [
        property_address,
        property_city,
        property_state,
        property_postal_code,
      ].every(Boolean)
    ) {
      setValue(
        'full_address',
        getFullAddress({
          propertyAddress: property_address,
          propertyCity: property_city,
          propertyState: property_state,
          propertyPostalCode: property_postal_code,
        })
      );
    }
  }, [property_address, property_city, property_state, property_postal_code]);

  return (
    <Box width="100%" flex="1" flexDirection="column" gap="16px">
      <Inputs.Text
        name="property_address"
        label="Address"
        placeholder="Enter address"
        containerProps={{ flex: 'unset' }}
        mode="onBlur"
      />
      <Box gap="16px">
        <Inputs.Text
          name="property_city"
          label="City"
          placeholder="Enter city"
          mode="onBlur"
        />
        <Inputs.StateSelect
          name="property_state"
          label="State"
          placeholder="Select"
          mode="onBlur"
        />
      </Box>
      <Box gap="16px">
        <Inputs.Text
          name="property_unit"
          label="Unit"
          placeholder="Enter unit"
          containerProps={{
            note: 'Optional',
          }}
        />
        <Inputs.Text
          name="property_postal_code"
          label="Zip code"
          placeholder="Enter zip code"
          containerProps={{ maxWidth: '140px' }}
          mode="onBlur"
        />
      </Box>
      <Box gap="16px" cursor="pointer">
        <Paragraph variant="text-small" color="#46B6FF" onClick={setManual}>
          Back to address search
        </Paragraph>
      </Box>
    </Box>
  );
};

// ====================================================================
// Automatic Search

type AutomaticSearchProps = {
  setManual: () => void;
};

const AutomaticSearch = ({ setManual }: AutomaticSearchProps) => {
  const { watch, setValue, setError, clearErrors } = useFormContext();
  const address = watch('property_address');

  const onEnter = ({
    hl_full_address,
    city,
    state_or_province,
    postal_code,
    full_address,
  }) => {
    clearErrors('full_address');
    setValue('property_address', hl_full_address);
    setValue('full_address', full_address);
    setValue('property_city', city);
    setValue('property_state', state_or_province);
    setValue('property_postal_code', postal_code);
  };

  useEffect(() => {
    clearErrors('full_address');
  }, []);

  return (
    <Box
      width="100%"
      flexDirection={{ xs: 'column', sm: 'row' }}
      flex="1"
      gap="16px"
    >
      <Inputs.Address
        name="full_address"
        placeholder="Search address"
        dropdownPlaceholder="Type to search an address"
        emptyResultsText="No matching address found"
        containerProps={{ width: '100%' }}
        toggleManualAddress={setManual}
        onClear={() =>
          onEnter({
            hl_full_address: null,
            city: null,
            state_or_province: null,
            postal_code: null,
            full_address: null,
          })
        }
        onBlur={() => {
          if (!address) {
            setError('full_address', {
              type: 'onBlur',
              message: 'Please choose an address from the dropdown menu',
            });
          }
        }}
        onChange={() => {
          clearErrors('full_address');
        }}
        onEnter={onEnter}
        boxSizing="border-box"
      />
      <Inputs.Text
        name="property_unit"
        placeholder="Unit (Optional)"
        containerProps={{
          minWidth: '160px',
          note: 'Optional',
        }}
      />
    </Box>
  );
};

// ====================================================================
// Departing Residence

export const DepartingResidence = () => {
  const { storedValues, updateStoredValues } = useSaveFlowRepInfo(
    LOCAL_STORAGE_ADDRESS_SEARCH_SETTINGS,
    {
      isManual: false,
    }
  );
  const [manual, setManual] = useState(false);

  const { onCheckStateEligibility } = useLeadSubmissionContext();
  const { watch } = useFormContext();

  const { full_address } = watch();

  const disable = !full_address;

  const handleNext = () => {
    updateStoredValues({ isManual: manual });
    onCheckStateEligibility();
  };

  const render = () => {
    if (manual) {
      return <ManualSearch setManual={() => setManual(false)} />;
    }
    return <AutomaticSearch setManual={() => setManual(true)} />;
  };

  useEffect(() => {
    if (storedValues?.isManual) {
      setManual(true);
    }
  }, []);

  return (
    <>
      <Content>
        <Container
          title="Where is the departing residence?"
          subtitle="This is the home your client is selling"
          gap="32px"
        >
          {render()}

          <Alert title="Why we ask this" mb={{ xs: '24px', md: '40px' }}>
            We’ll use the address to review if the property is eligible for the
            program.
          </Alert>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={() => handleNext()} disabled={disable} />
      </Footer>
    </>
  );
};
