import { useFlags } from 'flagsmith/react';

import { PropsWithChildren, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  Box,
  BoxTypes,
  Button,
  FadeIn,
  Icon,
  IconTypeProp,
  Match,
  Paragraph,
} from '@hl-portals/ui';

import { config, formatCurrency } from '@hl-portals/helpers';

import { useModal, useScreenSize } from '@hl-portals/hooks';

import { getEquityUnlockRange } from '../../../helpers';
import { FormData } from '../../../metadata/forms';
import { LoanOfficerApprovalType } from '../../../metadata/types';
import { ResultHeader } from '../../layout';

// =================================================================
// Icons

const SuccessIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28Z"
      fill="#87D8BB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 28C50 40.1503 40.1503 50 28 50C15.8497 50 6 40.1503 6 28C6 15.8497 15.8497 6 28 6C40.1503 6 50 15.8497 50 28Z"
      fill="#259B71"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.6175 35.6858L37.6925 23.3485C38.1025 22.9296 38.1025 22.2503 37.6925 21.8314L36.2076 20.3142C35.7976 19.8953 35.1327 19.8953 34.7226 20.3142L24.875 30.3757L20.2774 25.6782C19.8674 25.2593 19.2025 25.2593 18.7924 25.6782L17.3075 27.1954C16.8975 27.6143 16.8975 28.2936 17.3075 28.7125L24.1325 35.6857C24.5426 36.1047 25.2074 36.1047 25.6175 35.6858Z"
      fill="white"
    />
  </svg>
);

// =================================================================
// Card

type RootProps = PropsWithChildren<
  {
    hasShadow?: boolean;
  } & BoxTypes
>;

const Root = ({ children, hasShadow, ...props }: RootProps) => (
  <Box
    p="10px 10px 16px 10px"
    flexDirection="column"
    bgcolor="white"
    borderRadius="28px"
    border="1px solid #EEF0F6"
    boxShadow={hasShadow && '0px 3px 16px 0px rgba(108, 129, 171, 0.20)'}
    {...props}
  >
    {children}
  </Box>
);

type HeadingProps = {
  icon: IconTypeProp;
  title: string;
  subtitle: string;
  badge?: string;
};

const Heading = ({ icon, title, subtitle, badge }: HeadingProps) => (
  <Box
    p={{ xs: '16px', md: '24px 40px 24px 28px' }}
    flexDirection="column"
    borderRadius="18px 18px 0px 0px"
    bgcolor="#F2F9FE"
  >
    <Box
      mb="20px"
      flexDirection={{ xs: 'column', md: 'row' }}
      gap={{ xs: '8px', md: 'unset' }}
      alignItems="center"
    >
      <Box mr="12px" p="12px" borderRadius="4px" bgcolor="white">
        <Icon type={icon} color="#46B6FF" fill="#46B6FF" />
      </Box>
      <Paragraph mr="8px" variant="heading-4">
        {title}
      </Paragraph>
      {badge && (
        <Box p="3px 6px" borderRadius="16px" bgcolor="#E9F6FF">
          <Paragraph
            variant="text-xsmall"
            color="#0C6EBA"
            textTransform="uppercase"
            letterSpacing="1px"
          >
            {badge}
          </Paragraph>
        </Box>
      )}
    </Box>

    <Paragraph color="#55585E">{subtitle}</Paragraph>
  </Box>
);

const Alert = ({ children }: PropsWithChildren) => (
  <Box p="12px" gap="6px">
    <Icon type="infoFilled" fill="#F1B707" />
    <Paragraph variant="text-small" color="#705300">
      {children}
    </Paragraph>
  </Box>
);

const Footer = ({ children }: PropsWithChildren) => (
  <Box mt="14px" alignSelf="flex-end" alignItems="center" gap="16px">
    {children}
  </Box>
);

const Card = {
  Root,
  Heading,
  Alert,
  Footer,
};

// =================================================================
// Cards

const ExpressApprovalCard = () => {
  const {
    'equity-lead-submission-flow-phase-2': { enabled: isLeadSubmissionPhase2 },
  } = useFlags(['equity-lead-submission-flow-phase-2']);
  const { watch } = useFormContext();
  const { submit_response } = watch();

  const token = submit_response?.token;
  const link = `${config.agentDashboardUrl}/public/tasks/bbys_submit_property_photos?token=${token}`;

  const onCopy = (url: string) => {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(url).then(() => {
        toast('Link successfully copied to clipboard!', {
          type: 'success',
        });
      });
    }
  };

  return (
    <Card.Root hasShadow>
      <Card.Heading
        icon="images"
        title="Property Photos"
        subtitle="Upload recent photos of the departing residence to help us evaluate the property's condition"
      />
      {!isLeadSubmissionPhase2 && (
        <Card.Footer>
          <Button
            as="a"
            href={link}
            target="_blank"
            color="primary-ghost"
            iconRight={<Icon type="externalLink" fill="#46B6FF" />}
          >
            Open link
          </Button>
          <Button
            color="secondary"
            slim
            rounded
            size="large"
            onClick={() => onCopy(link)}
          >
            Copy link
          </Button>
        </Card.Footer>
      )}

      {isLeadSubmissionPhase2 && (
        <Card.Footer>
          <Paragraph
            display="flex"
            alignItems="center"
            color="blue500"
            onClick={() => onCopy(link)}
            cursor="pointer"
          >
            Copy link
            <Icon
              m={{ xs: '0px 4px', md: '0px 4px' }}
              type="link"
              fill="#46B6FF"
            />
          </Paragraph>
          <Button
            slim
            rounded
            color="primary"
            href={link}
            target="_blank"
            size="large"
          >
            Upload photos
          </Button>
        </Card.Footer>
      )}
    </Card.Root>
  );
};

const LightApprovalCard = () => {
  return (
    <Card.Root hasShadow>
      <Card.Heading
        icon="search"
        title="HomeLight is reviewing the application"
        subtitle="We have all the information we need and our team is reviewing the client application"
      />
      <Card.Alert>
        We'll be in contact within 24 hours to go over next steps
      </Card.Alert>
    </Card.Root>
  );
};

const InstantApprovalCard = () => {
  return (
    <Card.Root hasShadow>
      <Card.Heading
        icon="fileSignature"
        title="Sign program agreement"
        subtitle="Work with your client to review and sign the program agreement"
        badge="Assigned to Lender"
      />
      <Card.Alert>
        We'll be in contact within 24 hours to go over next steps
      </Card.Alert>
    </Card.Root>
  );
};

type AgentApprovalCardProps = {
  loanOfficerEmail?: string;
};

const AgentApprovalCard = ({ loanOfficerEmail }: AgentApprovalCardProps) => {
  return (
    <Card.Root hasShadow>
      <Card.Heading
        icon="bankSearch"
        title="Financial details"
        subtitle="We need additional financial information from the lender to asses the client's eligibility and to determine the final equity unlock amount"
        badge="Assigned to Lender"
      />
      <Card.Alert>
        {loanOfficerEmail
          ? `We notified the lender at ${loanOfficerEmail}`
          : 'We’ll contact you to connect your client with a lender'}
      </Card.Alert>
    </Card.Root>
  );
};

// =================================================================
// List

type ListItemProps = {
  label: string;
  value: string;
};

const ListItem = ({ label, value }: ListItemProps) => (
  <Box
    p="16px 0px"
    flex="1"
    justifyContent="space-between"
    gap="20px"
    borderBottom="1px solid #DBDFE6"
  >
    <Paragraph fontWeight="bold">{label}</Paragraph>
    <Paragraph textAlign="right">{value}</Paragraph>
  </Box>
);

type ListProps = PropsWithChildren<{
  title: string;
}>;

const List = ({ title, children }: ListProps) => (
  <Box flexDirection="column">
    <Paragraph
      color="#8E929C"
      fontWeight="bold"
      textTransform="uppercase"
      letterSpacing="2px"
    >
      {title}
    </Paragraph>
    {children}
  </Box>
);

// =================================================================
// Summary Modal

type SummaryModalProps = {
  data: FormData;
  closeModal: () => void;
};

const SummaryModal = ({ data, closeModal }: SummaryModalProps) => {
  const {
    current_flow,
    full_address,
    estimated_home_value,
    equity_unlock_result,
    primary_client_name,
    primary_client_email,
    primary_client_phone,
    has_additional_client,
    additional_client_name,
    additional_client_email,
    additional_client_phone,
    target_equity_unlock,
    dr_agent_present,
    agent_name,
    agent_email,
    agent_phone,
    sales_consultant_name,
    sales_consultant_email,
    sales_consultant_phone,
    lo_present,
    lender_company_name,
    loan_officer_name,
    loan_officer_email,
    loan_officer_phone,
  } = data;

  const { low_end_range, high_end_range } = equity_unlock_result || {};
  const equityUnlockRange = getEquityUnlockRange(low_end_range, high_end_range);
  const homeValue = formatCurrency({ value: estimated_home_value });

  const showAgentList = current_flow === 'agent' || dr_agent_present;
  const showLenderList = current_flow === 'lender' || lo_present;

  return (
    <Box pt="64px" flexDirection="column" gap="24px">
      <List title="Departing Residence Information">
        <ListItem label="Departing Residence" value={full_address} />
        <ListItem label="Estimated Home Value" value={homeValue} />
        <ListItem label="Equity Unlock Amount" value={equityUnlockRange} />
        {current_flow === 'lender' && (
          <ListItem
            label="Target Equity Amount"
            value={formatCurrency({
              value: target_equity_unlock,
            })}
          />
        )}
      </List>

      <List title="Client(s) Information">
        <ListItem label="Primary Title Holder" value={primary_client_name} />
        <ListItem label="Email Address" value={primary_client_email} />
        <ListItem label="Phone Number" value={primary_client_phone} />
        {has_additional_client && (
          <>
            <ListItem
              label="Additional Title Holder"
              value={additional_client_name}
            />
            <ListItem label="Email Address" value={additional_client_email} />
            <ListItem label="Phone Number" value={additional_client_phone} />
          </>
        )}
      </List>

      {showAgentList && (
        <List title="Agent Information">
          <ListItem
            label="Departing Residence Agent"
            value={agent_name || sales_consultant_name}
          />
          <ListItem
            label="Email Address"
            value={agent_email || sales_consultant_email}
          />
          <ListItem
            label="Phone Number"
            value={agent_phone || sales_consultant_phone}
          />
        </List>
      )}

      {showLenderList && (
        <List title="Lender Information">
          <ListItem label="Loan Officer" value={loan_officer_name} />
          <ListItem label="Company" value={lender_company_name} />
          <ListItem label="Email Address" value={loan_officer_email} />
          <ListItem label="Phone Number" value={loan_officer_phone} />
        </List>
      )}

      <Paragraph color="#72757D">
        We sent a copy of the summary to your email
      </Paragraph>
      <Button size="large" onClick={closeModal}>
        Close
      </Button>
    </Box>
  );
};

// =================================================================
// Success

export const Success = () => {
  const { watch } = useFormContext();
  const { isMobile } = useScreenSize();
  const { openModal, closeModal } = useModal();

  const {
    current_flow,
    loan_officer_email,
    agent_email,
    full_address,
    equity_unlock_result,
    estimated_home_value,
    submit_response,
  } = watch();

  const { low_end_range, high_end_range } = equity_unlock_result || {};
  const equityUnlockRange = getEquityUnlockRange(low_end_range, high_end_range);

  const contactEmail =
    current_flow === 'lender' ? loan_officer_email : agent_email;

  const loanOfficerApprovalType: LoanOfficerApprovalType = useMemo(() => {
    if (submit_response?.instant_approval_success) return 'instant';
    if (submit_response?.light_approval_success) return 'light';
    return 'express';
  }, [submit_response]);

  return (
    <Box width="100%" minHeight="100vh" flexDirection="column">
      <ResultHeader />

      <FadeIn flex="1" justifyContent="center" bgcolor="#F8F8FB">
        <Box
          maxWidth="768px"
          p={{ xs: '16px', md: '58px 0 120px 0' }}
          flexDirection="column"
        >
          <Box
            mb={{ xs: '32px', md: '56px' }}
            alignSelf="center"
            flexDirection="column"
            alignItems="center"
          >
            <SuccessIcon />
            <Paragraph
              mt={{ xs: '16px', md: '32px' }}
              mb={{ xs: '8px', md: '16px' }}
              variant={isMobile ? 'heading-4' : 'heading-1'}
              textAlign="center"
            >
              Application Received
            </Paragraph>
            <Paragraph textAlign="center">
              We sent a confirmation email to{' '}
              <Paragraph as="span" fontWeight="bold">
                {contactEmail}
              </Paragraph>
            </Paragraph>
          </Box>

          <Box mb="32px" flexDirection="column" gap="32px">
            <Paragraph variant="heading-4">Next Steps</Paragraph>

            {current_flow === 'lender' ? (
              <Match<LoanOfficerApprovalType>
                value={loanOfficerApprovalType}
                with={{
                  express: () => <ExpressApprovalCard />,
                  instant: () => <InstantApprovalCard />,
                  light: () => <LightApprovalCard />,
                }}
              />
            ) : (
              <AgentApprovalCard loanOfficerEmail={loan_officer_email} />
            )}
          </Box>

          <Box flexDirection="column" gap="32px">
            <Paragraph variant="heading-4">Application Summary</Paragraph>

            <Card.Root p="24px">
              <Box flexDirection="column">
                <ListItem label="Departing Residence" value={full_address} />
                <ListItem
                  label="Estimated Home Value"
                  value={
                    estimated_home_value
                      ? formatCurrency({ value: estimated_home_value })
                      : '$0'
                  }
                />
                <ListItem
                  label="Equity Unlock Amount "
                  value={equityUnlockRange}
                />

                <Paragraph
                  mt="24px"
                  textAlign="center"
                  color="#1192E5"
                  cursor="pointer"
                  onClick={() =>
                    openModal(
                      <SummaryModal
                        data={watch() as FormData}
                        closeModal={closeModal}
                      />
                    )
                  }
                >
                  See more
                </Paragraph>
              </Box>
            </Card.Root>
          </Box>
        </Box>
      </FadeIn>
    </Box>
  );
};
