import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Paragraph } from '@hl-portals/ui';

import { useModal } from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';
import { ReviewEligibilityStatusModal } from '../../review-eligibility-status-modal';

export const Trust = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();
  const { watch, setValue } = useFormContext();
  const { openModal, closeModal, isModalOpened } = useModal();

  const { departing_residence_held_in_trust, has_accepted_trust_modal } =
    watch();

  const onSelect = (selection: boolean | null) => {
    setValue('departing_residence_held_in_trust', selection);

    if (!selection) {
      onUpdateSnapshot({ departing_residence_held_in_trust: selection });
      return;
    }

    if (has_accepted_trust_modal && selection) {
      onUpdateSnapshot({ departing_residence_held_in_trust: selection });
      return;
    }
  };

  useEffect(() => {
    if (has_accepted_trust_modal) {
      return;
    }

    if (departing_residence_held_in_trust && !isModalOpened) {
      openModal(
        <ReviewEligibilityStatusModal
          onConfirm={() => {
            setValue('has_accepted_trust_modal', true);
            onUpdateSnapshot({ departing_residence_held_in_trust });
            closeModal({ clearAll: true });
          }}
          title={
            <Paragraph fontWeight="600" pb="4px">
              Property held in a trust
            </Paragraph>
          }
          description={
            <Paragraph fontWeight="400">
              Our program is not available for properties held in an{' '}
              <strong>irrevocable trust</strong>. For other types of trusts,
              proceed with your application as usual. We will require trust
              documents for review prior to final approval.
            </Paragraph>
          }
        />,
        { hideCloseIcon: true, enableClose: false }
      );
    }
  }, [departing_residence_held_in_trust, has_accepted_trust_modal]);

  return (
    <>
      <Content>
        <Container title="Is the departing residence held in a trust?">
          <Box flex="1" flexDirection="column">
            <Box mb="20px" flexDirection="column" gap="16px">
              <CardRadio
                title="Yes"
                onClick={() => onSelect(true)}
                isActive={departing_residence_held_in_trust === true}
              />
              <CardRadio
                title="No"
                isActive={departing_residence_held_in_trust === false}
                onClick={() => onSelect(false)}
              />
              <CardRadio
                title="Unsure"
                isActive={departing_residence_held_in_trust === null}
                onClick={() => onSelect(null)}
              />
            </Box>
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            If the departing residence is in an{' '}
            <strong>irrevocable trust</strong> it will not be eligible for the
            program.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() =>
            onUpdateSnapshot({ departing_residence_held_in_trust })
          }
          disabled={departing_residence_held_in_trust === undefined}
        />
      </Footer>
    </>
  );
};
