import { useFormContext } from 'react-hook-form';

import { Alert, Box } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const AgeRestrictedCommunity = () => {
  const { onUpdateSnapshot, onSetFlowError } = useLeadSubmissionContext();

  const { watch, setValue } = useFormContext();
  // no_age_restricted_community is asking: is your client's property in a restricted community?
  // if it is, the answer should be NO / False
  // if it is not, the answer should be YES / True
  // DEBT: update variable name to something descriptive in both FE & BE
  const { no_age_restricted_community } = watch();

  const onSelect = (selection: boolean) => {
    setValue('no_age_restricted_community', selection);
    if (selection == false) {
      onSetFlowError('dr_in_age_restricted_community', {
        no_age_restricted_community: selection,
      });
      return;
    }
    onUpdateSnapshot({ no_age_restricted_community: selection });
  };

  const disabled = !no_age_restricted_community;

  return (
    <>
      <Content>
        <Container
          title="Is the departing residence located in an age restricted community?"
          subtitle="For example a 55+ retirement community."
        >
          <Box flex="1" flexDirection="column">
            <Box mb="20px" flexDirection="column" gap="16px">
              <CardRadio
                title="Yes"
                onClick={() => onSelect(false)}
                isActive={no_age_restricted_community === false}
                justifyContent="center"
              />
              <CardRadio
                title="No"
                isActive={no_age_restricted_community === true}
                onClick={() => onSelect(true)}
                justifyContent="center"
              />
            </Box>
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            Buy Before You Sell is currently not available for age-restricted
            communities.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() => onUpdateSnapshot({ no_age_restricted_community })}
          disabled={disabled}
        />
      </Footer>
    </>
  );
};
